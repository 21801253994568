// smooth scroll for links (including scroll-to-top btn)
var scrollTrigger = document.querySelectorAll('.js-scroll-trigger').forEach((item, i) => {
    item.addEventListener("click", function(event){
        event.preventDefault();

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = this.hash;
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            if (target.length) {
                document.querySelector(target).scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }

    }, {passive: true})
});


// show/hide scroll-to-top btn
function handleScroll() {
    let windowY = window.scrollY;

    if (windowY >= 200) {
        // Scrolling DOWN
        // document.querySelector('.scroll-to-top').style.display = 'block';
        document.querySelector('.scroll-to-top').classList.remove('easeout');
        document.querySelector('.scroll-to-top').classList.add('easein');
    } else {
        // Scrolling UP
        // document.querySelector('.scroll-to-top').style.display = 'none';
        document.querySelector('.scroll-to-top').classList.remove('easein');
        document.querySelector('.scroll-to-top').classList.add('easeout');
    }
}
document.addEventListener("scroll", handleScroll, {passive: true});

console.log('No te quedes con el antojo. Pídelos para llevar. \n - El Pitillal: 322-289-1518 \n - Suc. López Mateos:L 322-356-5486');
